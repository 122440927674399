import * as React from 'react';
import { MarkdownRemark } from '../../graphql-types';

interface Props {
    readonly pageContext: {
        articles: MarkdownRemark[];
    };
}

export default class BaseOverviewTemplate extends React.Component<Props> {
    render() {
        return (
            <div>
                <div>{this.renderArticles()}</div>
                <hr />
                {/* <pre>{JSON.stringify(this.props.pageContext, null, 4)}</pre> */}
            </div>
        );
    }

    private renderArticles() {
        const { articles } = this.props.pageContext;

        return (
            <div>
                {articles.map((article, i) => {
                    return (
                        <div>
                            <a
                                href={`/blog/${article.frontmatter.slug}`}
                                key={i}
                            >
                                {article.frontmatter.title}
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    }
}
